import React from 'react';
import {FaWindowMaximize} from 'react-icons/fa'; // Import Rocket Icon

const Header = () => {
  return (
    <header className="sticky top-0 z-50 bg-white shadow-lg p-4 w-full">
      <div className="w-full flex items-center justify-between">
        {/* Logo */}
        <img
          src="/assets/logo.svg"
          alt="Logo"
          className="h-12 w-auto"
        />

        {/* Title */}
        <h1 className="flex-1 text-left text-xl md:text-xl lg:text-2xl font-semibold text-gray-900 tracking-tight">
           Web Comparator Tool
        </h1>

        <a
          href="https://github.com/ahmedeasypeasy/"
          className="relative inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-white bg-blue-600 border border-blue-600 rounded-md hover:bg-white hover:text-blue-600 hover:border-blue-600 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1 hover:scale-105 active:scale-100"
        >
          <FaWindowMaximize className="mr-2 text-base" />
          <span>Talk to Author</span>
        </a>
        
      </div>
    </header>
  );
};

export default Header;
