import React, { useState } from "react";
import axios from "axios";
import { DotLoader } from "react-spinners";
import { FaDesktop, FaMobileAlt } from "react-icons/fa";
import { FiLink } from "react-icons/fi";

function Form({ setResult }) {
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [platform, setPlatform] = useState("desktop");
  const [blockAds, setBlockAds] = useState(false);  // Updated state for Block Ads
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare the values to be sent to the backend
    const viewMode = platform === "desktop" ? "desktop" : "mobile";
    const blockAdsValue = blockAds ? "ON" : "OFF";  // Set value of blockAds

    try {
      const response = await axios.post("https://compare.threatinsights.org/compare/", {
        url1,
        url2,
        viewMode,  // Send the view mode (desktop or mobile)
        blockAds: blockAdsValue,  // Send blockAds value (ON or OFF)
      });

      setResult(response.data);
    } catch (error) {
      console.error("Error comparing URLs:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-xl rounded-lg p-6 mt-8 w-full max-w-lg mx-auto"
    >
      {/* Compact URL Inputs */}
      <div className="grid grid-cols-1 gap-4 mb-4">
        {/* URL 1 */}
        <div className="relative">
          <FiLink className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-lg" />
          <input
            type="text"
            value={url1}
            onChange={(e) => setUrl1(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent transition-all placeholder-gray-400 text-sm"
            placeholder="Enter the first URL"
            required
            disabled={loading}
          />
        </div>

        {/* URL 2 */}
        <div className="relative">
          <FiLink className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-lg" />
          <input
            type="text"
            value={url2}
            onChange={(e) => setUrl2(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent transition-all placeholder-gray-400 text-sm"
            placeholder="Enter the second URL"
            required
            disabled={loading}
          />
        </div>
      </div>

      {/* Refined Platform and Ads Section */}
      <div className="flex items-center justify-between mb-4">
        {/* Platform Selection */}
        <div className="flex items-center space-x-3">
          <label className="text-gray-700 text-sm font-semibold">Platform:</label>
          <div className="flex space-x-1">
            <button
              type="button"
              className={`px-3 py-2 text-sm rounded-md border transition-all ${
                platform === "desktop"
                  ? "bg-blue-600 text-white border-blue-600"
                  : "border-gray-300 text-gray-700 hover:bg-gray-100"
              }`}
              onClick={() => setPlatform("desktop")}
            >
              <FaDesktop className="inline-block mr-1" /> Desktop
            </button>

            <button
              type="button"
              className={`px-3 py-2 text-sm rounded-md border transition-all ${
                platform === "mobile"
                  ? "bg-blue-600 text-white border-blue-600"
                  : "border-gray-300 text-gray-700 hover:bg-gray-100"
              }`}
              onClick={() => setPlatform("mobile")}
            >
              <FaMobileAlt className="inline-block mr-1" /> Mobile
            </button>
          </div>
        </div>

        {/* Block Ads Toggle */}
        <div className="flex items-center space-x-2">
          <label className="text-gray-700 text-sm font-semibold">Block Ads:</label>
          <div
            className="relative inline-block w-10 h-5"
            onClick={() => setBlockAds(!blockAds)}  // Toggle blockAds
          >
            <input
              type="checkbox"
              checked={blockAds}
              readOnly
              className="opacity-0 w-0 h-0"
            />
            <div
              className={`absolute cursor-pointer inset-0 rounded-full transition bg-gray-300 ${
                blockAds ? "bg-green-500" : ""
              }`}
            ></div>
            <div
              className={`absolute left-1 top-1 h-3 w-3 rounded-full bg-white shadow-md transition-transform transform ${
                blockAds ? "translate-x-5" : ""
              }`}
            ></div>
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all duration-300 disabled:opacity-50 flex items-center justify-center text-sm"
        disabled={loading}
      >
        {loading ? <DotLoader size={20} color={"#ffffff"} /> : "Compare"}
      </button>
    </form>
  );
}

export default Form;
